<page-header class="text-2xl">
    <fa-icon *ngIf="icon" [icon]="icon" class="pr-1.5"></fa-icon>
    <a [queryParams]="params" [routerLink]="'/levels/' + route" class="cursor-pointer pr-1">{{ title }}</a>
    <span-gentle>({{ total }} total)</span-gentle>
</page-header>

<ng-container [ngSwitch]="type">
    <carousel *ngSwitchCase="CategoryPreviewType.Carousel">
        <level-preview *ngFor="let level of levels" [level]="level"></level-preview>
    </carousel>

    <div *ngSwitchCase="CategoryPreviewType.List" class="flex flex-col gap-2">
        <container *ngFor="let level of levels">
            <level-preview [level]="level"></level-preview>
        </container>
        <secondary-button *ngIf="levels && total > levels.length" [queryParams]="params"
                          [routerLink]="'/levels/' + route"
                          text="More"></secondary-button>
    </div>
</ng-container>
