<page-header-block *ngIf="user">
  <div class="flex gap-x-3.5">
    <user-avatar [user]="user" size="h-auto w-44"></user-avatar>
    <div class="flex-grow">
      <page-header class="text-3xl inline">
        {{ user.username }}'s profile
        <span-gentle>{{ getMomentJoined(user.joinDate) }}</span-gentle>
      </page-header>

      <div [ngSwitch]="room">
        <div *ngSwitchCase="undefined">
          <span-gentle>Offline (last seen {{getMomentSeen(user.lastLoginDate)}})</span-gentle>
        </div>
        <div *ngSwitchDefault>
          <span-gentle>Playing a {{room!.LevelType}} level</span-gentle>
        </div>
      </div>

      <div class="bg-backdrop rounded px-5 py-2.5 drop-shadow-lg mt-2 whitespace-pre-wrap text-foreground">
        <p>{{user.description.length == 0 ? 'This person hasn\'t introduced themselves yet.' : user.description}}</p>
      </div>
    </div>
  </div>
</page-header-block>

<page-header-block *ngIf="ownUser">
  <div class="flex gap-x-3.5 text-foreground">
    <secondary-button [icon]="faCog" text="Edit Profile" routerLink="/settings" class="w-48" *ngIf="user?.userId == ownUser.userId"></secondary-button>
    <admin-link-button [routerLink]="'/admin/user/' + user?.userId" *ngIf="ownUser?.role == UserRoles.Admin"></admin-link-button>
  </div>
</page-header-block>

<page-header-block *ngIf="!user">
  <div class="flex gap-3.5 animate-pulse">
    <div class="inline h-44 w-44 bg-secondary rounded-md"></div>
    <div>
      <div class="inline-block h-8 w-80 bg-secondary rounded-full"></div>
      <br>
      <div class="inline-block h-4 w-48 bg-secondary rounded-full"></div>
      <br><br>

      <div class="inline-block h-6 w-96 bg-secondary rounded-full"></div>
    </div>
  </div>
</page-header-block>

<br>
