<form>
  <form-holder>
    <page-header class="text-3xl">Forgor Password</page-header> <!-- I'm sorry. -->

    <form-input [icon]="faEnvelope" name="Email Address" type="email" [(value)]="email"></form-input>

    <div class="flex flex-row my-1 justify-between space-x-4">
      <primary-button [icon]="faSkull" class="w-full" text="Send" (click)="send()"></primary-button>
      <dangerous-button [icon]="faCancel" class="min-w-fit" text="Cancel" onclick="window.history.back()"></dangerous-button>
    </div>
  </form-holder>
</form>
