<page-header class="text-3xl">{{total}} Users</page-header>

<a *ngFor="let user of users" class="bg-form-background mb-2.5 p-2.5 rounded flex flex-row" [routerLink]="'/admin/user/' + user.userId">
  <user-avatar [user]="user" size="h-auto w-24" class="mr-2.5"></user-avatar>
  <div class="flex flex-col text-xl">
    <span class="font-bold">{{user.username}} ({{getRole(user?.role)}})</span>
    <code>{{user.userId}}</code>
    <span-gentle>{{user.emailAddress}} (verified: {{user.emailAddressVerified}})</span-gentle>
  </div>
</a>

<div intersectionObserver (visibilityChange)="loadNextPage($event)"></div>
