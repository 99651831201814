<form-holder>
    <page-header class="text-3xl">Password Reset</page-header>

    <form-input [icon]="faKey" name="Password" type="password" [(value)]="password"></form-input>
    <form-input [icon]="faKey" name="Confirm Password" type="password" [(value)]="confirmPassword"></form-input>

    <div class="flex flex-row my-1 justify-between space-x-4">
        <primary-button [icon]="faKey" class="w-full" text="Reset" (click)="reset()"></primary-button>
        <dangerous-button [icon]="faCancel" class="min-w-fit" text="Cancel" onclick="window.history.back()"></dangerous-button>
    </div>
</form-holder>
