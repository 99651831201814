<div class="group relative inline-block">
  <ng-content></ng-content>
  <span class="opacity-0 invisible group-hover:opacity-100 group-hover:visible
  absolute z-10 mt-1 left-1/2 transform -translate-x-1/2
  py-2.5 px-5 bg-backdrop flex items-center justify-center
  transition-opacity duration-300
  not-italic text-foreground font-normal text-base whitespace-nowrap
  rounded drop-shadow-lg" *ngIf="_active">
    {{_text}}
  </span>
</div>
