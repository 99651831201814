<page-header class="text-3xl">Recent Activity</page-header>

<div *ngIf="activity">
  <div *ngFor="let page of activity">
    <div *ngFor="let event of page.events">
      <activity-event [event]="event" [page]="page"></activity-event>
    </div>
  </div>
  <div intersectionObserver (visibilityChange)="loadNextPage($event)"></div>
  <secondary-button (click)="loadNextPage(true)" text="Load more" *ngIf="nextPageIndex > 0"></secondary-button>
</div>

<div *ngIf="!activity">
  <div *ngFor="let i of GenerateEmptyList(10)">
    <activity-event></activity-event>
  </div>
</div>
