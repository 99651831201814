<form>
  <form-holder>
    <page-header class="text-3xl">Verify Email</page-header>

    <form-input [icon]="faKey" name="Code" type="text" [(value)]="code"></form-input>

    <primary-button [icon]="faCheckCircle" class="min-w-full" text="Verify" (click)="verify()"></primary-button>

    <divider class="w-full"></divider>
    <p>Didn't get the email?</p>
    <secondary-button [icon]="faMailReply" text="Resend Verification Code" (click)="resend()"></secondary-button>
  </form-holder>

</form>
