<h1>
    <app-contest-banner *ngIf="contest" [contest]="contest"></app-contest-banner>
</h1>

<div class="flex xl:justify-between xl:flex-row flex-col pt-4 gap-2">
    <div class="flex flex-col gap-2">
        <div class="flex gap-2">
            <container class="xl:w-96 w-full">
                <div class="h-24 items-center flex">
                    <div [ngClass]="hasStarted() && !hasEnded() ? 'flex-col' : 'flex-col-reverse'"
                         class="text-center w-full flex">
                        <p [ngClass]="hasStarted() && !hasEnded() ? 'text-3xl' : ''">{{ endsIn }}</p>
                        <p [ngClass]="!hasStarted() ? 'text-3xl' : ''">{{ startsIn }}</p>
                    </div>
                </div>
            </container>
        </div>
        <container>
            <tooltip text="Allowed games">
                <p class="text-xl text-secondary-bright inline-block">{{ formatAllowedGames() }}</p></tooltip>
            <tooltip text="Theme">
                <p *ngIf="contest?.contestTheme"
                   class="text-xl text-secondary-bright inline-block whitespace-pre">{{ ' | ' + contest?.contestTheme }}</p>
            </tooltip>
            <tooltip text="Tag">
                <p class="text-xl text-secondary-bright inline-block whitespace-pre">{{ ' | ' + contest?.contestTag }}</p>
            </tooltip>
        </container>
    </div>

    <div class="flex flex-col items-end xl:w-1/3 gap-2">
        <div class="xl:w-48 w-full">
            <secondary-button *ngIf="ownUser?.role == UserRoles.Admin || contest?.organizer?.userId == ownUser?.userId"
                              [icon]="faPen"
                              [routerLink]="'/contests/' + contest?.contestId + '/manage'"
                              text="Manage contest"></secondary-button>
        </div>
        <div class="flex-col gap-2 w-full">
            <div *ngIf="contest?.templateLevel">
                <page-header class="text-2xl">
                    <fa-icon class="pr-1.5"></fa-icon>
                    <a class="cursor-pointer pr-1">Template Level</a>
                </page-header>
                <container>
                    <level-preview [level]="contest!.templateLevel"></level-preview>
                </container>
            </div>
        </div>
    </div>
</div>

<divider class="w-2/3"></divider>


<div class="flex gap-4 flex-row">
    <markdown [data]="contest?.contestDetails" class="xl:w-2/3"></markdown>

    <category-preview *ngIf="contest && levelEntries" [levels]="levelEntries.items"
                      [query]="{'contest': contest.contestId}" [total]="levelEntries.listInfo.totalItems"
                      [type]="CategoryPreviewType.List" category-title="Entries"
                      class="xl:block hidden w-1/3" route="contest"></category-preview>
</div>

<category-preview *ngIf="contest && levelEntries" [levels]="levelEntries.items"
                  [query]="{'contest': contest.contestId}" [total]="levelEntries.listInfo.totalItems"
                  [type]="CategoryPreviewType.Carousel" category-title="Entries"
                  class="xl:hidden" route="contest"></category-preview>

