<div class="flex gap-2 cursor-default" *ngIf="level">
    <tooltip text="Yays">
        <fa-icon [icon]="faThumbsUp"></fa-icon>
        {{level.yayRatings}}
    </tooltip>
    <tooltip text="Boos">
        <fa-icon [icon]="faThumbsDown"></fa-icon>
        {{level.booRatings}}
    </tooltip>
    <tooltip text="Hearts">
        <fa-icon [icon]="faHeart"></fa-icon>
        {{level.hearts}}
    </tooltip>
    <tooltip text="Unique Plays">
        <fa-icon [icon]="faPlay"></fa-icon>
        {{level.uniquePlays}}
    </tooltip>
    <tooltip text="Cool Rating (CR)">
        <fa-icon [icon]="faStar"></fa-icon>
        {{level.score | number:'1.0-1'}}
    </tooltip>
</div>
