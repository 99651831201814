<page-header class="text-3xl">{{title}}</page-header>
<p>{{description}}</p>

<ngx-masonry [options]="masonryOptions" [ordered]="true" *ngIf="categories">
  <container ngxMasonryItem *ngFor="let c of categories" class="masonry-grid-item">
    <div class="text-xl font-medium">
      <fa-icon class="mr-2" [icon]="getIcon(c.fontAwesomeIcon)"></fa-icon>
      <a routerLink="/levels/{{c.apiRoute}}">{{c.name}}
        <fa-icon class="text-sm text-gentle" [icon]="faLink"></fa-icon>
      </a>
    </div>
    <p>{{c.description}}</p>
    <div *ngIf="c.previewLevel">
      <divider></divider>
      <level-preview [level]="c.previewLevel"></level-preview>
    </div>
  </container>
</ngx-masonry>

<ngx-masonry [options]="masonryOptions" [ordered]="true" *ngIf="!categories">
  <container ngxMasonryItem *ngFor="let i of GenerateEmptyList(3)" class="masonry-grid-item">
    <div class="animate-pulse">
      <div class="text-xl font-medium">
        <div class="inline-block bg-secondary w-5 h-5 rounded-md mr-2"></div>
        <div class="inline-block h-5 w-36 bg-secondary rounded-full"></div>
      </div>

      <div class="inline-block h-4 w-56 bg-secondary rounded-full"></div>

      <divider></divider>
      <level-preview></level-preview>
    </div>
  </container>
</ngx-masonry>
