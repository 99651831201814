<ng-container> <!-- Load custom fonts for themes that require them -->
    <ng-container *ngIf="getTheme() == 'lighthouse'"> <!-- Poppins -->
        <link rel="stylesheet" as="style" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&subset=latin,latin-ext&display=swap">
    </ng-container>
    <ng-container *ngIf="getTheme() == 'hack'"> <!-- Ubuntu Mono -->
        <link rel="stylesheet" as="style" href="https://fonts.googleapis.com/css?family=Ubuntu%20Mono:300,400,500,700&subset=latin,latin-ext&display=swap">
    </ng-container>
    <ng-container *ngIf="getTheme() == 'soundShapes'"> <!-- Sora -->
        <link rel="stylesheet" as="style" href="https://fonts.googleapis.com/css?family=Sora:300,400,500,700&subset=latin,latin-ext&display=swap">
    </ng-container>
</ng-container>

<div class="bg-header-background text-header-foreground border-b-backdrop default:border-b-2 h-[59px] items-center flex-col"
    [ngClass]="themeService.GetUseFullPageWidth() ? '' : 'flex'">
  <div class="items-center flex h-[59px]" [ngClass]="themeService.GetUseFullPageWidth() ? 'px-5' : 'container'">
    <div class="flex mr-2.5">
      <a class="bg-logo bg-cover cursor-pointer w-12 h-12 drop-shadow-lg" *ngIf="getTheme() != 'hotdogStand'" routerLink="/" title="Home"></a>
      <a class="bg-cover cursor-pointer w-12 h-12 drop-shadow-lg text-5xl leading-[3rem]" *ngIf="getTheme() == 'hotdogStand'" routerLink="/" title="Home">🌭</a>
      <div class="ml-3 w-[3px] rounded-sm bg-divider"></div>
    </div>

    <nav class="text-xl leading-none">
      <a *ngFor="let link of routerLinks" class="mr-2 p-3 inline-block" routerLink="{{link.link}}" [attr.aria-label]="link.title" [title]="link.title">
        <fa-icon [icon]="link.icon" class="inline-block"></fa-icon>
        <span *ngIf="link.title.length > 0" class="pl-2.5 inline-block max-md:invisible max-md:absolute">{{link.title}}</span>
      </a>
    </nav>

    <fa-icon (click)="toggleNotifications()" [icon]="faBell" class="text-xl flex-shrink ml-auto p-1.5 m-1.5 cursor-pointer" *ngIf="user"></fa-icon>

    <button (click)="toggleMenu()" *ngIf="user" aria-label="More..." class="p-1.5 m-1.5 cursor-pointer">
      <user-avatar [user]="user" size="h-11 w-auto" class="ml-4"></user-avatar>
    </button>

    <button (click)="toggleLogin()" class="flex-shrink ml-auto p-3 text-2xl" *ngIf="!user" aria-label="Sign In">
      <fa-icon [icon]="faSignIn" class="inline-block"></fa-icon>
    </button>
  </div>
</div>

<div class="w-full flex justify-center">
  <div class="flex h-0 z-50" [ngClass]="themeService.GetUseFullPageWidth() ? 'w-full px-5' : 'container'">
    <div class="flex-grow"></div> <!-- force all menus to be on the right -->
    <div class="drop-shadow-lg" #login [hidden]="true" *ngIf="!user">
      <app-login></app-login>
    </div>

    <div class="drop-shadow-lg" #menu [hidden]="true" *ngIf="user">
      <app-menu [links]="rightSideRouterLinks"></app-menu>
    </div>

    <div class="drop-shadow-lg" #notifications [hidden]="true" *ngIf="user">
      <form-holder>
        <app-notification-listing></app-notification-listing>
      </form-holder>
    </div>
  </div>
</div>

<app-banner *ngIf="instance?.maintenanceModeEnabled && user?.role !== UserRoles.Admin" title="Maintenance mode"
  text="The server is currently in maintenance mode, meaning only administrators can use the website right now.
  Check back later."
  color="warning" [icon]="faExclamationTriangle" [id]="-1"></app-banner>

<app-banner *ngIf="user?.role == UserRoles.Restricted" [title]="'You are restricted!'"
  text="Your account is currently in restricted mode. You can still play, but you won't be able to publish levels,
  post comments, or otherwise interact with the community. For more information, please contact an administrator."
  color="dangerous" [icon]="faExclamationTriangle" [id]="-1"></app-banner>

<app-banner *ngIf="user !== undefined && user?.emailAddress === undefined" [title]="'Add an email address'"
  text="Your account is missing an email address. Please add one in settings, or you may not be able to log in again."
  color="dangerous" [icon]="faExclamationTriangle"></app-banner>

<app-banner *ngIf="user !== undefined && !user?.emailAddressVerified" [title]="'Verify your email address'"
  text="Your account's email address is not verified. Please check your email for a verification code, and verify it in settings.
  If you do not see an email verification code, try resending the email or checking your spam folder."
  color="warning" [icon]="faExclamationTriangle"></app-banner>

<app-banner *ngFor="let n of bannerService.banners"
            [title]="n.Title" [text]="n.Text" [color]="n.Color" [icon]="n.Icon"
            [id]="bannerService.banners.indexOf(n)"
/>

<div [ngClass]="themeService.GetUseFullPageWidth() ? '' : 'container mx-auto'">
    <div class="bg-backdrop p-5 grid min-h-full h-full mb-10 text-foreground lighthouse:border-x border-divider"
         [@routeAnimations]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
    </div>
</div>
