<div *ngIf="photo === undefined">
    <page-header class="text-3xl">Oops! 404!</page-header>

    <p>We couldn't find that photo. It may have been deleted by its author.</p>
    <p>Sorry. ¯\_(ツ)_/¯</p>
    <br>
    <text-link onclick="window.history.back()">Take me back!</text-link>
</div>

<div *ngIf="photo !== undefined">
  <photo [photo]="photo ?? undefined" [link]="false"></photo>
</div>
