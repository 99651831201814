<form>
  <form-holder>
    <page-header class="text-3xl">Sign Up</page-header>

    <p class="text-warning">
      <fa-icon [icon]="faTriangleExclamation" class="mr-1"></fa-icon>
      Remember to make your username match your PSN/RPCN id!
    </p>

    <form-input [icon]="faUser" name="Username" type="text" [(value)]="username"></form-input>
    <form-input [icon]="faEnvelope" name="Email Address" type="text" [(value)]="email"></form-input>
    <form-input [icon]="faKey" name="Password" type="password" [(value)]="password"></form-input>
    <form-input [icon]="faKey" name="Confirm Password" type="password" [(value)]="confirmPassword"></form-input>

    <primary-button class="min-w-full" [icon]="faUserPlus" text="Register" (click)="register()"></primary-button>

    <divider class="w-full"></divider>

    <p>Already have an account?</p>
    <secondary-button [icon]="faSignIn" text="Log in" routerLink="/login"></secondary-button>
  </form-holder>
</form>
