<ng-container *ngIf="_level">
  <div class="flex items-center gap-x-2">
    <div>
      <level-avatar [level]="_level" size="h-20 w-auto"></level-avatar>
    </div>
    <div>
      <a [routerLink]="'/level/' + _level.levelId" class="text-xl font-medium mr-1 lighthouse:text-primary" *ngIf="_level.title.length > 0">{{_level.title}}</a>
      <a [routerLink]="'/level/' + _level.levelId" class="text-xl font-medium mr-1 lighthouse:text-primary" *ngIf="_level.title.length <= 0">Unnamed Level</a>
      <tooltip text="This level has been team picked." *ngIf="_level.teamPicked">
        <fa-icon [icon]="faCircleCheck" class="pr-1"></fa-icon>
      </tooltip>
      <level-statistics [level]="_level" class="text-sm"></level-statistics>
      <p-gentle>by <tooltip [active]="_level.isReUpload"
          [text]="'This level is a re-upload, originally published by ' + (_level.originalPublisher === null ? 'an unknown publisher' : _level.originalPublisher) + '.'"><user-link [user]="_level.publisher" class="pl-0.5" *ngIf="_level.publisher"
                                                       [ngClass]="_level.isReUpload ? 'line-through' : ''"></user-link>

              <span class="pl-0.5">{{ _level.originalPublisher }}</span>
      </tooltip></p-gentle>
      <p-gentle>Created in {{getGameVersion(_level.gameVersion)}} <date [date]="_level.publishDate"></date></p-gentle>
    </div>
  </div>

  <ng-container *ngIf="_description && _level.description.length > 0">
    <divider></divider>
    <p class="overflow-hidden whitespace-pre-wrap">{{_level.description}}</p>
  </ng-container>
</ng-container>

<div *ngIf="!_level" class="animate-pulse">
  <div class="flex items-center gap-x-2">
    <div class="bg-secondary w-20 h-20 rounded-full"></div>

    <div>
      <div class="inline-block h-5 w-36 bg-secondary rounded-full"></div><br>
      <div class="inline-block h-4 w-56 bg-secondary rounded-full"></div><br>
      <div class="inline-block h-4 w-56 bg-secondary rounded-full"></div>
    </div>
  </div>
</div>
