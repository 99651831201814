<div *ngIf="_notification" class="rounded bg-secondary px-5 py-2.5">
  <span class="font-medium text-lg">
    <fa-icon [icon]="getIcon(_notification.fontAwesomeIcon)" class="mr-1"></fa-icon>
    {{_notification.title}}
  </span>

  <span-gentle>
    <date [date]="_notification.createdAt"></date>
  </span-gentle>

  <!-- close button-->
  <fa-icon class="float-right text-xl mr-2.5 text-secondary-bright cursor-pointer" [icon]="'xmark'" (click)="raiseClearEvent()"></fa-icon>
  <br>
  <span>{{_notification.text}}</span>
</div>
