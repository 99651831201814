<div class="flex mb-5">
  <div class="font-medium text-3xl w-full">{{registrations?.length}} Queued Registrations</div>
  <div class="w-36 text-center">
    <dangerous-button text="Clear All" [icon]="faTrash" (click)="clearAll()"></dangerous-button>
  </div>
</div>

<p *ngIf="(registrations?.length ?? 0) == 0">Nobody has sent any registration requests yet.</p>

<div *ngFor="let registration of registrations" class="bg-form-background rounded px-5 py-2.5 mb-2.5">
  <div class="float-right cursor-pointer rounded-md bg-dangerous w-7 h-7 text-center" (click)="remove(registration)">
    <fa-icon [icon]="faTrash"></fa-icon>
  </div>

  <p class="text-xl font-bold">{{registration.username}}</p>
  <p>{{registration.emailAddress}}</p>
  <p-gentle>Expires <date [date]="registration.expiryDate"></date></p-gentle>
</div>
