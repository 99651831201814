<div *ngIf="_event && getUserFromEvent() as user" class="flex pb-2.5 gap-2">
  <div>
    <user-avatar [user]="user" size="h-11 w-auto"></user-avatar>
  </div>
  <div>
    <a [routerLink]="'/u/' + user.userId" class="font-medium pr-1">{{user.username}}</a>
    <ng-container [ngSwitch]="_event.eventType" class="inline" *ngIf="_contextIsLevel; else notLevelContext">
      <span *ngSwitchCase="0">published this level</span>
      <span *ngSwitchCase="1">gave this level a heart</span>
      <span *ngSwitchCase="5">played this level</span>
      <span *ngSwitchCase="10">took a photo in this level</span>
      <span *ngSwitchCase="13">team picked this level</span>
      <div *ngSwitchCase="20" class="inline">
        <span *ngIf="getScoreFromEvent() as score">got a score of
          <b>{{score.score}}</b>
          in <b>{{score.scoreType}}-player</b> mode
        </span>
      </div>
      <span *ngSwitchDefault>Unhandled case: {{_event.eventType}}</span>
    </ng-container>
    <ng-template [ngSwitch]="_event.eventType" #notLevelContext>
      <span *ngSwitchCase="0">published <b><level-link [level]="getLevelFromEvent()"></level-link></b></span>
      <span *ngSwitchCase="1">gave <b><level-link [level]="getLevelFromEvent()"></level-link></b> a heart</span>
      <span *ngSwitchCase="3">gave <b><user-link [user]="getOtherUserFromEvent()"></user-link></b> a heart</span>
      <span *ngSwitchCase="5">played <b><level-link [level]="getLevelFromEvent()"></level-link></b></span>
      <span *ngSwitchCase="10">took a <b><a [routerLink]="'/photo/' + getPhotoFromEvent().photoId">photo</a></b></span>
      <span *ngSwitchCase="13">team picked <b><level-link [level]="getLevelFromEvent()"></level-link></b></span>
      <div *ngSwitchCase="20" class="inline">
        <span *ngIf="getScoreFromEvent() as score">got a score of
          <b>{{score.score}}</b>
          on
          <b><level-link [level]="score.level"></level-link></b>
          in <b>{{score.scoreType}}-player</b> mode
        </span>
      </div>
      <span *ngSwitchCase="127">logged in for the first time</span>
      <span *ngSwitchDefault>Unhandled case: {{_event.eventType}}</span>
    </ng-template>
    <p-gentle>
      <date [date]="_event.occurredAt"></date>
    </p-gentle>
  </div>
</div>

<div *ngIf="!_event" class="flex pb-2.5 gap-2 animate-pulse">
  <div class="h-11 w-11 rounded-md bg-secondary"></div>
  <div>
    <div class="inline-block h-4 w-56 bg-secondary rounded-full pt-2.5"></div><br>
    <div class="inline-block h-3 w-24 bg-secondary rounded-full pt-2.5"></div>
  </div>
</div>
