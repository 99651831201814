<form-holder>
  <page-header class="text-3xl">Log out?</page-header>
  <div>
    <p>Are you sure you want to log out?</p>
    <p>You're currently signed in as <b>{{ authService.user?.username }}</b>.</p>
  </div>

  <divider></divider>

  <dangerous-button text="Yes, sign me out." (click)="authService.LogOut()"></dangerous-button>
  <secondary-button text="No, take me back!" onclick="window.history.back()"></secondary-button>
</form-holder>
