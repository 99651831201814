<page-header class="text-3xl">Photos</page-header>

<div *ngIf="photos">
  <p>Users have uploaded {{total}} photos!</p>
  <ngx-masonry [options]="masonryOptions" [ordered]="true">
    <container ngxMasonryItem *ngFor="let photo of photos" class="masonry-grid-item">
      <photo [photo]="photo"></photo>
    </container>
  </ngx-masonry>
  <div intersectionObserver (visibilityChange)="loadNextPage($event)"></div>

  <secondary-button (click)="loadNextPage(true)" text="Load more" *ngIf="nextPageIndex > 0"></secondary-button>
</div>

<div *ngIf="!photos">
  <div class="inline-block h-5 w-56 bg-secondary rounded-full pt-2.5 animate-pulse"></div>
  <ngx-masonry [options]="masonryOptions" [ordered]="true">
    <container ngxMasonryItem *ngFor="let i of GenerateEmptyList(20)"
         class="m-1 masonry-grid-item">
      <photo [photo]=""></photo>
    </container>
  </ngx-masonry>
</div>
