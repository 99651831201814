<div class="flex mb-5" *ngIf="notifications?.length ?? 0 > 0">
  <div class="font-medium text-3xl w-full">{{notifications?.length}} notifications</div>
  <div class="w-36 text-center">
    <dangerous-button text="Clear All" [icon]="faTrash" (click)="clearAllNotifications()"></dangerous-button>
  </div>
</div>

<div class="grid gap-3">
    <refresh-notification *ngFor="let notification of notifications" [notification]="notification" (clearEvent)="clearNotification($event)"></refresh-notification>
</div>

<div *ngIf="notifications?.length == 0">
  <page-header class="text-3xl">No notifications</page-header>
  <p>You're all caught up!</p>
</div>
