<div *ngIf="instance?.announcements?.length ?? 0">
  <page-header class="text-2xl">
    <fa-icon [icon]="faBullhorn" class="pr-1"></fa-icon>
    Server Announcements
  </page-header>

  <div class="flex flex-col gap-2.5">
    <announcement *ngFor="let announcement of instance?.announcements" [title]="announcement.title" [body]="announcement.text"></announcement>
  </div>
  <divider></divider>
</div>

<div *ngIf="instance && instance.activeContest" class="mb-5">
    <a [routerLink]="'/contests/' + instance.activeContest.contestId">
        <app-contest-banner [contest]="instance.activeContest"></app-contest-banner>
    </a>
</div>

<div class="text-center bg-form-background md:rounded-md py-32 default:bg-hero soundShapes:bg-ss-hero soundShapes:text-header-foreground bg-cover drop-shadow-lg">
    <page-header class="text-4xl">Welcome to {{instance?.instanceName ?? "Refresh"}}!</page-header>
    <p *ngIf="instance">{{instance.instanceDescription}}</p>
    <div class="h-1"></div>

    <div *ngIf="authService.user !== undefined">
        <p>Welcome back, <b>{{authService.user!.username}}</b>.</p>
    </div>

    <p>There are currently <b>{{statistics?.currentIngamePlayersCount ?? 0}}</b> players online across <b>{{statistics?.currentRoomCount ?? 0}}</b> rooms.</p>

    <p *ngIf="instance">
        Having trouble?
        <a [href]="'mailto:' + instance.contactInfo.emailAddress" class="text-secondary-bright hover:underline">Contact us.</a>
    </p>
</div>

<div *ngIf="!authService.user" class="flex justify-center gap-x-20 py-5 max-sm:flex-col max-sm:gap-y-5">
  <primary-button text="Create an account" [icon]="faUserPlus" class="flex-grow" routerLink="/register"></primary-button>
  <secondary-button [text]="'Sign into ' + (instance?.instanceName ?? 'Refresh')" [icon]="faArrowRightToBracket" class="flex-grow" routerLink="/login"></secondary-button>
</div>

<div *ngIf="activity && activity.events.length > 0">
  <divider></divider>
  <page-header class="text-2xl">
    <a routerLink="/activity">
      <fa-icon [icon]="faFireAlt" class="pr-1"></fa-icon>
      What's New?
    </a>
  </page-header>

  <div *ngFor="let event of activity.events">
    <activity-event [event]="event" [page]="activity"></activity-event>
  </div>
</div>

<div *ngIf="busyLevels && busyLevels.items.length > 0">
  <divider></divider>
  <category-preview [levels]="busyLevels.items" [total]="busyLevels.listInfo.totalItems" category-title="Popular Now" [icon]="faPlayCircle" route="currentlyPlaying"></category-preview>
</div>

<div *ngIf="coolLevels && coolLevels.items.length > 0">
  <divider></divider>
  <category-preview [levels]="coolLevels.items" [total]="coolLevels.listInfo.totalItems" category-title="Cool Levels" [icon]="faCalendar" route="coolLevels"></category-preview>
</div>

<div *ngIf="pickedLevels && pickedLevels.items.length > 0">
  <divider></divider>
  <category-preview [levels]="pickedLevels.items" [total]="pickedLevels.listInfo.totalItems" category-title="Team Picks" [icon]="faFire" route="teamPicks"></category-preview>
</div>
