<page-header class="text-3xl">API Documentation</page-header>
<div *ngIf="!routes">
  Loading...
</div>

<div *ngFor="let route of routes" class="px-5 py-2.5 rounded bg-background mb-5">
  <div class="flex text-2xl align-middle gap-2.5 text-center items-center mb-1.5">
    <div *ngIf="route.method == 'GET'" class="bg-api-retrieve rounded px-2.5 py-1 flex">
      <pre>{{route.method}}</pre>
    </div>
    <div *ngIf="route.method == 'POST' || route.method == 'PUT' || route.method == 'PATCH'" class="bg-api-push rounded px-2.5 py-1 flex">
      <pre>{{route.method}}</pre>
    </div>
    <div *ngIf="route.method == 'DELETE'" class="bg-api-remove rounded px-2.5 py-1 flex">
      <pre>{{route.method}}</pre>
    </div>
    <pre>/api/v3{{route.routeUri}}</pre>

    <div *ngIf="route.authenticationRequired" class="text-sm bg-warning rounded px-2.5 py-1 flex">
      <fa-icon [icon]="'key'" class="pr-1"></fa-icon>
      <p>Authentication required</p>
    </div>
  </div>

  <p>{{route.summary}}</p>
  <p class="text-xl font-bold py-2">Parameters</p>

  <p *ngIf="route.parameters.length === 0">This API route takes no parameters.</p>
  <div *ngFor="let parameter of route.parameters">
    <div class="flex text-xl align-middle gap-2.5 text-center items-center mb-1.5">
      <div class="bg-backdrop rounded px-2.5 py-1 flex">
        <pre>{{parameter.type}}</pre>
      </div>
      <pre *ngIf="parameter.type === 'Route'">{{'{' + parameter.name + '}'}}</pre>
      <pre *ngIf="parameter.type === 'Query'">?{{parameter.name}}</pre>
    </div>
    <p>{{parameter.summary}}</p>
  </div>

  <p class="text-xl font-bold py-2">Potential Errors</p>

  <p *ngIf="route.potentialErrors.length === 0">This API route returns no errors.</p>
  <div *ngFor="let error of route.potentialErrors">
    <div class="flex text-xl align-middle gap-2.5 text-center items-center mb-1.5">
      <div class="bg-dangerous rounded px-2.5 py-1 flex">
        <pre>{{error.name}}</pre>
      </div>
      <p>{{error.occursWhen}}</p>
    </div>
  </div>
</div>
