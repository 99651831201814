<page-header class="text-3xl">Authentication</page-header>
<divider></divider>

<div class="flex">
  <page-header class="text-2xl grow">IP Verification Requests</page-header>
  <secondary-button class="float-right" text="Refresh" (click)="refresh()"></secondary-button>
</div>
<p-gentle *ngIf="requests?.length == 0">There doesn't seem to be anything here...</p-gentle>
<container *ngFor="let request of requests" class=" flex">
  <div class="w-full">
    <p class="text-xl font-bold">{{request.ipAddress}}</p>
    <p-gentle>Sent <date [date]="request.createdAt"></date></p-gentle>
  </div>
  <div class="text-center align-top flex gap-2 text-xl float-right">
    <div class="rounded-full bg-success p-2.5 w-10 h-10 cursor-pointer" (click)="approve(request.ipAddress)">
      <fa-icon [icon]="faCheck"></fa-icon>
    </div>

    <div class="rounded-full bg-dangerous p-2.5 w-10 h-10 cursor-pointer" (click)="deny(request.ipAddress)">
      <fa-icon [icon]="faXmark"></fa-icon>
    </div>
  </div>
</container>
