<div class="bg-warning px-5 py-2.5 rounded drop-shadow-lg">
  <div>
    <fa-icon [icon]="faBullhorn" class="pr-1.5"></fa-icon>
    <span class="text-xl font-bold">{{this._title}}</span>
    <div class="float-right cursor-pointer rounded-md bg-dangerous w-7 h-7 text-center" *ngIf="_removable" (click)="remove()">
      <fa-icon [icon]="faTrash"></fa-icon>
    </div>
  </div>
  <p>{{this._body}}</p>
</div>
