<page-header class="text-3xl capitalize" title="/api/v3/levels/{{apiRoute}}">{{ routeCategory?.name }}</page-header>

<div *ngIf="levels && routeCategory">
    <p>{{ routeCategory.description }}</p>
    <p>There are {{ total }} total levels to browse in {{ routeCategory.name }}.</p>
    <ngx-masonry [options]="masonryOptions" [ordered]="true">
        <container *ngFor="let l of levels" class="masonry-grid-item" ngxMasonryItem>
            <level-preview [description]="true" [level]="l"></level-preview>
        </container>
    </ngx-masonry>
    <div (visibilityChange)="loadNextPage($event)" intersectionObserver></div>

    <secondary-button (click)="loadNextPage(true)" *ngIf="nextPageIndex > 0" text="Load more"></secondary-button>
</div>

<div *ngIf="!levels">
    <div class="inline-block h-5 w-56 bg-secondary rounded-full pt-2.5 animate-pulse"></div>
    <ngx-masonry [options]="masonryOptions" [ordered]="true">
        <container *ngFor="let i of GenerateEmptyList(10)" class="masonry-grid-item"
                   ngxMasonryItem>
            <level-preview [description]="true" [level]=""></level-preview>
        </container>
    </ngx-masonry>
</div>
