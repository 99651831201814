<page-header class="text-3xl">Edit {{ level?.title ?? '...' }}</page-header>
<p>(id: <code>{{ level?.levelId ?? 0 }}</code>)</p>
<divider></divider>

<div *ngIf="(ownUser?.role ?? 0) >= UserRoles.Curator">
    <page-header class="text-2xl">Admin/Curator Settings</page-header>
    <div class="flex flex-col w-96 max-lg:w-auto gap-y-2.5">
        <form-dropdown name="Game Version" [options]="gameVersions" [(value)]="gameVersion"></form-dropdown>

        <div>
            <form-checkbox name="Team Picked" [(value)]="teamPicked" [icon]="faCertificate"></form-checkbox>
            <form-checkbox name="Mark as re-upload" [(value)]="isReUpload" [icon]="faClone"></form-checkbox>
        </div>

        <form-input name="Original Publisher" [(value)]="originalPublisher" [icon]="faUser"></form-input>

        <primary-button [icon]="faFloppyDisk" text="Save changes" (click)="update()"></primary-button>
    </div>
</div>
<divider></divider>

<div class="flex max-lg:flex-col gap-x-10 gap-y-2.5">
    <div class="flex flex-col gap-y-2.5">
        <page-header class="text-2xl">Metadata</page-header>
        <form-input name="Title" [icon]="faCertificate" [(value)]="title" class="font-bold"></form-input>
        <form-input name="Description" [icon]="faPencil" [multiline]="true" [(value)]="description"></form-input>

        <div class="flex flex-col gap-y-1">
            <primary-button [icon]="faFloppyDisk" text="Save changes" (click)="update()"></primary-button>
            <div class="flex flex-row gap-x-1">
                <secondary-button [icon]="faCancel" text="Cancel" class="flex-grow"
                                  (click)="cancel()"></secondary-button>
                <dangerous-button [icon]="faTrash" text="Delete level" (click)="delete()"></dangerous-button>
            </div>
        </div>
    </div>

    <div *ngIf="level" class="bg-header-background text-header-foreground p-5 rounded flex-grow">
        <page-header class="text-2xl">Preview</page-header>
        <div class="flex gap-3.5">
            <div class="group flex h-auto w-44 max-md:w-24 justify-center items-center aspect-square my-2.5">
                <input type="file" (change)="iconChanged($event).then()" class="hidden" id="pfpInput">
                <label for="pfpInput"
                       class="flex rounded-full bg-[#000000aa] h-auto w-44 max-md:w-24 justify-center items-center aspect-square z-10 opacity-0 group-hover:opacity-100 transition-opacity">
                    <fa-icon [icon]="faPencil" class="text-4xl"></fa-icon>
                </label>
                <img [src]="GetAssetImageLink(iconHash)"
                     class="h-auto w-44 max-md:w-24 rounded-full drop-shadow-lg absolute z-0"
                     onerror="this.onerror=null;this.src='/assets/missingLevel.svg';">
            </div>

            <div class="flex-grow">
                <div class="font-bold text-3xl mb-1">
                    {{ title.length == 0 ? 'Unnamed Level' : title }}
                </div>
                <div class="bg-backdrop text-foreground rounded px-5 py-2.5 drop-shadow-lg whitespace-pre-wrap">
                    <p>{{ description.length == 0 ? 'No description was provided for this level.' : description }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

