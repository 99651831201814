<page-header class="text-3xl">Manage {{user?.username}} ({{getRole(user?.role)}})</page-header>
<p>(id: <code>{{user?.userId}}</code>)</p>
<span-gentle>{{user?.emailAddress}} (verified: {{user?.emailAddressVerified}})</span-gentle>

<div class="flex mt-2.5">
  <secondary-button class="w-64" text="View User's Profile" [icon]="faUser" routerLink="/u/{{user?.userId}}"></secondary-button>
</div>

<divider></divider>
<div class="flex gap-x-5">
  <div class="w-[19rem]">
    <page-header class="text-2xl">Punish</page-header>
    <p-gentle *ngIf="user?.banReason !== null" class="block pb-2.5">
      <fa-icon [icon]="faGavel"></fa-icon>
      Notice: This user is already punished.
    </p-gentle>

    <form-input name="Reason" [icon]="faPencil" [(value)]="reason" [readonly]="user?.banReason !== null"></form-input>
    <form-input name="Expiry Date" [icon]="faCalendar" [(value)]="date" [readonly]="user?.banReason !== null" type="datetime-local"></form-input>

    <div class="flex flex-col gap-2.5" *ngIf="user?.banReason === null">
      <dangerous-button [icon]="faGavel" text="Ban {{user?.username}}" (click)="ban()"></dangerous-button>
      <secondary-button [icon]="faBan" text="Restrict {{user?.username}}" (click)="restrict()"></secondary-button>
    </div>

    <primary-button [icon]="faFlag" text="Pardon {{user?.username}}" (click)="pardon()" class="text-center" *ngIf="user?.banReason !== null"></primary-button>
  </div>
  <div class="w-[19rem]">
    <page-header class="text-2xl">Manage</page-header>
    <div class="flex flex-col gap-2.5">
      <dangerous-button [icon]="faEarthAmerica" text="Clear Planet Decorations" (click)="deletePlanets()"></dangerous-button>
      <dangerous-button [icon]="faTrash" text="Delete Account" (click)="delete()"></dangerous-button>
    </div>
  </div>
</div>
