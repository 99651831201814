<div *ngIf="_photo">
  <a [routerLink]="'/photo/' + _photo.photoId" *ngIf="_link">
    <img [src]="GetPhotoLink(_photo)" class="rounded-md w-full" alt="Photo">
  </a>

  <div *ngIf="!_link">
    <img [src]="GetPhotoLink(_photo)" class="rounded-md w-full" alt="Photo">
  </div>

  <div class="pt-2.5">
    <span>
      Taken by <user-link [user]="_photo.publisher" class="font-bold"></user-link>
      <ng-container *ngIf="_photo.level">
        in <level-link [level]="_photo.level" class="font-bold"></level-link>
      </ng-container>
    </span></div>
  <div class="text-sm text-gentle">
    <span *ngIf="_photo.subjects.length > 0">with </span>
    <span *ngFor="let subject of _photo.subjects; let last = last">
      <user-link [user]="subject.user!" *ngIf="subject.user !== null"></user-link>
      <span *ngIf="subject.user === null">{{subject.displayName}}</span>

      <span *ngIf="!last">, </span>
    </span>
  </div>
</div>

<div class="animate-pulse" *ngIf="!_photo">
  <div>
    <div class="block bg-secondary rounded-md w-full aspect-video"></div>
  </div>
  <br>

  <div class="inline-block h-5 w-36 bg-secondary rounded-full pt-2.5"></div><br>
  <div class="inline-block h-4 w-56 bg-secondary rounded-full"></div>
</div>
