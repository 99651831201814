<form>
  <form-holder>
    <page-header class="text-3xl">Sign into Refresh</page-header>

    <form-input [icon]="faEnvelope" name="Email Address" type="email" [(value)]="email"></form-input>
    <form-input [icon]="faKey" name="Password" type="password" [(value)]="password"></form-input>

    <primary-button [icon]="faSignIn" class="min-w-fit" text="Sign in" (click)="login()"></primary-button>
    <secondary-button [icon]="faKey" class="w-full" text="Forgot password?" routerLink="/forgotPassword"></secondary-button>

    <divider></divider>
    <p>Haven't signed up yet?</p>
    <secondary-button [icon]="faUserPlus" text="Create an account" routerLink="/register"></secondary-button>
  </form-holder>
</form>
